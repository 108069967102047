<template>
  <div class="picsWrap en">
    <div class="group" v-for="(item,index) in dataInfo" :key="index">
      <img :src="baseUrl + item.goods_image" alt />
      <div class="content">
        <div class="title">{{item.goods_name}}</div>
        <div class="detail">{{item.goods_info}}</div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";

export default {
  data() {
    return {
      itemList: [
        {
          src: "g1_1.png",
          title: "Cake",
          detail:
            "The signature dish of the villagers canteen is Weng jar chicken with golden skin and amber chicken oilIt overflows slowly and emits a pungent aroma. It tastes crispyChicken is tender, juicy and elastic. It has a unique taste and can be called a delicacy."
        },
        {
          src: "g1_2.png",
          title: "Chicken rolls",
          detail:
            "The signature dish of the villagers canteen is Weng jar chicken with golden skin and amber chicken oilIt overflows slowly and emits a pungent aroma. It tastes crispyChicken is tender, juicy and elastic. It has a unique taste and can be called a delicacy."
        },
        {
          src: "g1_3.png",
          title: "Chicken rolls",
          detail:
            "The signature dish of the villagers canteen is Weng jar chicken with golden skin and amber chicken oilIt overflows slowly and emits a pungent aroma. It tastes crispyChicken is tender, juicy and elastic. It has a unique taste and can be called a delicacy."
        }
      ],
      dataInfo: new Array()
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    // 获取数据
    this.getData(config.buyUrlShouBanLi_en);
  },
  methods: {
    async getData(url) {
      try {
        this.id = this.$route.query.id;
        let res = await axios.get(url, {
          params: {
            id: this.id
          }
        });
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            if (tempData.length > 0) {
              this.dataInfo = tempData;
            }

            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.picsWrap {
  > .group {
    width: 1004px;
    // height:264px;
    border-bottom: 1px solid $main-gray;
    padding-top: 35px;
    padding-left: 4px;
    padding-bottom: 35px;
    > img {
      width: 322px;
      height: 212px;
      vertical-align: top;
      margin-right: 20px;
    }
    > .content {
      width: 622px;
      height: 212px;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;

      > .title {
        padding-top: 18px;
        font-size: 30px;
        margin-bottom: 30px;
        color: $main-black;
      }
      > .detail {
        font-size: 18px;
        color: $font-grayDarkDD;
        line-height: 1.5;
      }
    }
  }
  > .group:nth-of-type(1) {
    padding-top: 10px;
  }
}
</style>
